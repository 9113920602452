<template>
    <div>
        <div
            class="all-share"
            ref="ball"
            @touchstart.stop='handleTouchstart'
            @touchmove.stop='handleTouchmove'
            @touchend.stop='handleTouchend'
            @click.stop="showSheet"
            :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
        >
            <img src="@/assets/friend.png" alt="">
        </div>
        <van-popup v-model="showShareImg" closeable close-icon="close" round position="bottom" :style="{ height: '500px',overflow: 'auto'}">
            <div class="all-share-img">
                <img :src="share_image_base64">
                <div v-if="!loading" class="all-share-remain">( 长按上方图片保存并分享 )</div>
                <div v-show="!loading" @click="toCopy" class="all-share-copy">
                    <img src="@/assets/link.png" alt="">
                    <span>复制链接</span>
                </div>
                <loadings :loading='loading' />
            </div>
        </van-popup>
    </div>
</template>

<script>
    import { Popup } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:"share",
        data() {
            return {
                moveTop:30,
                moveLeft:320,
                showShareImg:false,
                loading:false,
                share_image_base64:'',
                link_url:'',
            }
        },
        components: {
            [Popup.name]:Popup,
            loadings:Loadings,
        },
        methods: {
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            showSheet(){
                this.showShareImg = true
                let appid = localStorage.getItem('appid',)
                let name = `${appid}-Access-Token`
                if(!localStorage.getItem(name)){
                    localStorage.setItem('is_whiteList',1)
                }
                if(this.share_image_base64) return false
                this.loading = true
                this.$api.tools_share({share_url:location.href})
                .then(res=>{
                    this.loading = false
                    this.share_image_base64 = res.data.share_image_base64
                    this.link_url = res.data.link
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            toCopy(){
                let that = this
                this.$copyText(that.link_url).then(function (e) {
                    that.$toast.success('复制成功')
                }, function (e) {
                    that.$toast.error('复制出错')
                })
            }
        },
    }
</script>

<style lang="less">
    .all-share{
        position: fixed;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }
    .all-share-img{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        img{
            width: 187.5px;
            border-radius: 2px;
        }
    }
    .all-share-remain{
        margin-top: 10px;
    }
    .all-share-copy{
        margin-top:10px;
        color: #cdcdcd;
        display: flex;
        align-items: center;
        img{
            width: 24px;
            margin-right: 10px;
        }
    }
</style>