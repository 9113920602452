<template>
  <div v-show="!loading" class="container pd100">
    <van-swipe :autoplay="3000" class="swiper" indicator-color="#00de93">
      <van-swipe-item v-for="item in banner" :key="item.id">
        <van-image class="swiper-img" :src="item.image" alt="banner" />
      </van-swipe-item>
    </van-swipe>
    <div class="header">
      <div class="header-title">{{ detail.meeting_name }}</div>
      <div class="header-txt"><van-icon name="eye-o" class="mr5" />{{ detail.view_count }}</div>
    </div>
    <a :href="'tel:'+detail.meeting_hotline" style="display:block" class="van-hairline--bottom">
      <van-cell
        :title="detail.meeting_hotline"
        icon="phone-o"
        is-link
      />
    </a>
    <van-cell :title="detail.meeting_address" icon="location-o" />
    <van-cell :title="detail.meeting_price" icon="gold-coin-o" />
    <van-cell class="mb10" :title="detail.meeting_start_date + ' - ' + detail.meeting_end_date" icon="clock-o" />
    <van-cell v-if="detail.show_booked" class="mb10" title="报名人数" :value="detail.already_count + ' / ' + detail.ticket_count" />

    <van-cell title="活动简介" />
    <div class="desc bgf desc-mar">
      <div class="content ql-editor" style="padding-top:10px" v-html="detail.meeting_description" />
    </div>
    <div class="affix-bar">
      <van-button
        class="affix-bar-btn"
        block
        :disabled="btn_disabled"
        type="primary"
        @click="onSubmit"
      >立即报名</van-button>
    </div>
    <copyright v-show='!loading' />
    <share />
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Swipe, SwipeItem } from 'vant'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Copyright from '@/components/Copyright'
import Share from '@/components/Share'

export default {
  name: 'EventsDetail',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Copyright,
    Share
  },
  mixins: [PageMixin],
  data() {
    return {
      banner: [],
      detail: {},
      meet_id:'',
      loading:false,
      btn_disabled: false
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.loading = true
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        duration: 0
      })
      let meet = this.$route.query
      this.meet_id = meet.meeting_id
      this.$api.events_detail({ meeting_id: this.meet_id }).then(res => {
        this.$toast.clear()
        if(res.data.ticket_count > 0 ? res.data.already_count >= res.data.ticket_count : false){
          this.btn_disabled = true
        }
        if(res.data.status==3){
          this.btn_disabled = true
        }
        this.banner = [{ id: '111', image: res.data.meeting_cover }]
        this.detail = res.data
        this.loading = false
      })
    },
    onSubmit() {
      let pathStr = this.$route.query._source?`/ruiyi2021/submit?meeting_id=${this.meet_id}&_source=${this.$route.query._source}`:`/ruiyi2021/submit?meeting_id=${this.meet_id}`
      let pathStepperStr = this.$route.query._source?`/ruiyi2021/stepper/submit?meeting_id=${this.meet_id}&_source=${this.$route.query._source}`:`/ruiyi2021/stepper/submit?meeting_id=${this.meet_id}`
      this.$router.push({
        path: this.$route.query.stepper?pathStepperStr:pathStr
      })
    }
  },
}
</script>

<style lang="less" scoped>

  .swiper {
    &-img {
        width: 100%;
        height: 180px;
    }
  }
  .header{
    background: #fff;
    margin-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    padding: 0 15PX;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-title{
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        columns: #333;
    }
    &-txt{
        color: #999;
        font-size: 12px;
        display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .desc-mar{
    margin-bottom: 10px;
  }
</style>

